import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { columnCards, sliderCompanies, subpageContent } from "../utils/products/subpageInformation";

import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";
import InfoImage from "../components/info-image/info-image";
import Banner from "../components/banner/banner";
import { bannerCMS } from "../utils/generalInformation";




// markup
const Replacement = () => {


    return (
        <Layout
            SEOTitle={"Guaranteed Replacement Vehicle Insurance Products - United Legal Assistance"}
            SEODescription={"Client’s involved in non-fault motor accidents are usually provided with a like for like hire vehicle. What about the customers who are unfortunate enough to have been involved in a fault accident, theft, fire or total loss situation?"}
            footerDisclaimer={""}>

            <InfoImage props={subpageContent} origin="guaranteed" />
            <InfoImage props={subpageContent} origin="choices" />

            <Banner props={bannerCMS} />

            <Comparison props={columnCards} />

            <ImageCarousel props={sliderCompanies} />

        </Layout>
    )

}

export default Replacement